import React from "react"

import styled from "styled-components"

import { useBookDemoData } from "../hooks/useBookDemoData";

import ThemeDefault from "../theme/ThemeDefault";

import { GatsbyImage } from "gatsby-plugin-image"
import {device} from "../theme/MediaQueries"
import ParseHTML, { ParseAndRemoveTags } from "../helpers/ParseHTML";

import HubspotForms from "../Hubspot/HubspotForms";

import Tick from "../../assets/images/icons/tick.svg";


const HubspotFormSection = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .inner-div {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;

      & .title-col {
        align-items: center;
      }
      & h1  {
        text-align: center;
        & *  {
          text-align: center;
        }
      }
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 32px;
      text-align: left;
      font-weight: 500;
      
      p {
        margin-bottom: 20px;
      }
    }
    
    .form-col {
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      background: ${ThemeDefault.blue};
      padding: 24px;
      width: 100%;

      .form-title {
        font-size:  ${ThemeDefault.font_4};
        font-family: ${ThemeDefault.fontPrimary};
        line-height: 1.4;
        color: ${ThemeDefault.white}
      }
      
    }

    .hero-text {
      text-align: left;
      margin-bottom: 32px;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }
  }

  .points {
    margin-bottom: 32px;

    & .text {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      line-height: 1.3;
      margin-bottom: 8px;
    }
  }

  .tick {
    color: ${ThemeDefault.blue};
    width: 16px; 
    margin-right: 8px;
    flex-shrink: 0;
    flex-grow: 0;
  }
`

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};
  margin-top: 4px;

  .hero-inner {
    
    &.inner-div {
      padding: 0 !important;
      padding-bottom: 0 !important;

      & .gatsby-image-wrapper {
        height: 100%;
      }
    }

    flex-direction: row;
    display: flex;
    align-items: stretch;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 16px;
      text-align: left;
      font-weight: 500;

      @media ${device.MXmd} {
        font-size: ${ThemeDefault.font_6}
      }
      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .spacer {
      margin-bottom: 64px;

      @media ${device.MXmd} {
        margin-bottom: 32px;
      }
    }

    .title-col {
      padding-top: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      padding-left: calc(24px + 24px);
      padding-right: calc(24px + 24px);
      padding-bottom: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      width: 60%;
      justify-content: center;
      
      @media ${device.MXmd} {
        padding: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px) 24px;
        width: 75%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
    }

    .hero-text {
      text-align: left;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .image-col {
      width: 40%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
      @media ${device.MXmd} {
        width: 35%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }
      
    }

  }

  
`

const BookDemo = ( data ) => {
  
  const demoData = useBookDemoData();
  
  const {childImageSharp, publicURL} = demoData.page.bookDemoContent.heroImage !== null ? demoData.page.bookDemoContent.heroImage.imageFile : {childImageSharp: false, publicURL: false};

  return (
    <>
      <SectionComponent key={`hero-${data.index}`} bgColour={demoData.page.bookDemoContent.sectionBgColour} > 
        <div className="inner-div wrapper hero-inner">
          <div className="title-col">

            { demoData.page.bookDemoContent.title && <div className="hero-title"><h1>{ParseAndRemoveTags(demoData.page.bookDemoContent.title)}</h1></div> }
            
          </div>
          <div className="image-col">
            {
              childImageSharp ? 
                <GatsbyImage alt={demoData.page.bookDemoContent.altText ? demoData.page.bookDemoContent.altText : demoData.page.bookDemoContent.title.replace(/(<([^>]+)>)/gi, "")} image={childImageSharp.gatsbyImageData} className={demoData.page.bookDemoContent.className} /> :
                publicURL ? 
                <img alt={demoData.page.bookDemoContent.altText ? demoData.page.bookDemoContent.altText : demoData.page.bookDemoContent.title.replace(/(<([^>]+)>)/gi, "")} src={publicURL} className={demoData.page.bookDemoContent.className} /> 
                : ''
            }
          </div>
        </div>
      </SectionComponent>  

      <HubspotFormSection key={`form-section`} cssClass="m-top">
        <div className="inner-div">
          <div className="c-columns-2 c-columns-gap-xl c-columns-l-1">
            <div className="content-col">
              
              <div className="title">
                <div>{demoData.page.bookDemoContent.content && ParseHTML(demoData.page.bookDemoContent.content)}</div>
                <div className="points">
                  { demoData.page.bookDemoContent.points.length && demoData.page.bookDemoContent.points.map((point, index) => (
                    <div className="text" key={`tick-${index}`}>
                      <Tick className="tick" />{point.point}
                    </div>
                    ))
                  }
                </div>
              </div>
            
            </div>
            <div className="form-col" >
            
              <div className="form-wrap" >
                <div className="form-title">{demoData.page.bookDemoContent.hubspotTitle && ParseHTML(demoData.page.bookDemoContent.hubspotTitle)}</div>
                <div className="form-text">{demoData.page.bookDemoContent.hubspotText && ParseHTML(demoData.page.bookDemoContent.hubspotText)}</div>
                <HubspotForms formID={demoData.page.bookDemoContent.hubspotCode} blockID="strategy-form" blackButton="true" />
              </div>
    
            </div>
          </div>
        </div>
      </HubspotFormSection>

    </>
  );
}

export default BookDemo


