import React from "react"
import Base from "../templates/Base"
import { Helmet } from "react-helmet";

import BookADemo from "../BookADemo/BookADemo";

const SignUpPage = ({ pageContext }) => {
  return (
    <Base>
      <BookADemo data={pageContext} />
    </Base>
  );
};

export default SignUpPage;