import { useStaticQuery, graphql } from "gatsby"
export const useBookDemoData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query DemoQuery {
        wordpress {
          page(idType: URI, id: "book-a-demo") {
            bookDemoContent: strategySessionContent {
              title
              sectionBgColour
              points {
                point
              }
              hubspotCode
              hubspotTitle
              hubspotText
              content
              heroImage {
                altText
                srcSet
                sourceUrl
                imageFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                    fluid(maxWidth: 1200) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      presentationWidth
                    }
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wordpress
}
