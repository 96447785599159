import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import ThemeDefault from '../theme/ThemeDefault'
import useScript from '../helpers/useScript'
import Spinner from '../elements/Spinner'

const HubspotFormWrapper = styled.div.attrs((props) => ({
  className: props.cssClass,
}))`

    margin-top: 16px;
    min-height: ${props => props.height};

    .hubsport-form,
    .actions {
      margin: 0;
      padding: 0;
    }

    label:not(.hs-error-msg) {
      display: none !important;
    }

    .hs-error-msg {
      color: #d92a0e;
    }

    .actions,
    .input {
      padding: 0 !important;
      margin: 0 !important;
    }

    input,
    textarea {
      padding: 8px !important;
      border: 1px solid ${ThemeDefault.greyDark} !important;
      border-radius: 0 !important;
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      min-height: auto !important;
      box-sizing: border-box !important;
      font-size: 16px !important;
      line-height: 1 !important;
      height: auto !important;
    }

    .hs-button {
      display: block !important;
      padding: 16px 24px  !important;
      background-color: ${props => props.whiteButton ? `${ThemeDefault.white}` : (props.blackButton ? `${ThemeDefault.black}` : `${ThemeDefault.blue}`)};
      border: none  !important;
      box-shadow: 0 4px 6px rgba(50,50,93,0.11),0 1px 3px rgba(0,0,0,0.08)  !important;
      cursor: pointer  !important;
      line-height: 1  !important;
      outline: none  !important;
      text-decoration: none  !important;
      transition: all 0.15s ease  !important;
      white-space: nowrap  !important;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box !important;
      font-weight: 400 !important;
      color: ${props => props.whiteButton ? `${ThemeDefault.black}` : (props.blackButton ? `${ThemeDefault.white}` : `${ThemeDefault.black}`)};

    }

    ul, li {
      padding: 0 !important;
      margin: 0 !important;
      list-style: none !important;
    }

    .hubspot-form {
      .hs-form-field {
        margin-bottom: 16px !important;
      }
    }

    select {
      border: 1px solid ${ThemeDefault.greyDark} !important;
      border-radius: 0 !important;
      width: 100%;
      padding: 10px 8px !important;
      max-width: 100%;
      min-height: auto !important;
      box-sizing: border-box !important;
      font-size: 16px !important;
      line-height: 1 !important;
      height: auto !important;
      appearance: none;

    }

    .hs-fieldtype-select {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        right: 8px;
        top: 50%;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid ${ThemeDefault.black};
        transform: translateY(-50%);
      }
    }
`
// A helper component that supports adding a Hubspot form into the page. It
// does a few things to achieve this:
//
// - Injects the Hubspot form javascript library script tag, the effect used to
//   achieve this triggers re-renders of this component as the status of the
//   script loading changes.
// - Adds a DOM element to accept the eventual Hubspot form that will be
//   rendered, this is styled to match our own app requirements. This initially
//   has a loading spinner shown.
// - Waits until the Hubspot javascript is loaded, and our own DOM element is
//   available in the DOM then calls the Hubspot javascript and requests the
//   form be rendered, this removes the loading spinner and shows the form.
const HubspotForms = React.memo(({
  blockID,
  formID,
  formOptions = {},
  whiteButton,
  blackButton,
  formHeight = '100px',
}) => {

  const hsJsReady = useRef(false)
  const status = useScript('//js.hsforms.net/forms/v2.js')
  hsJsReady.current = status === 'ready'

  // It's important that we only try and invoke the creation of a Hubspot form
  // once this component has mounted the DOM element that we tell Hubspot to
  // inject their form into and the Hubspot javascript is available!
  useEffect(() => {
    if (hsJsReady.current && window.hbspt)
    {
      window.hbspt.forms.create({
        portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
        region: 'na1',
        formId: formID,
        cssClass: 'hubspot-form',
        target: `.${blockID}`,
        ...formOptions,
      })
    }
  }, [hsJsReady.current])

  const formReady = typeof window !== 'undefined' && hsJsReady.current

  return <>

    {!formReady &&
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: formHeight, marginBottom: '0px'}}>
      <Spinner dark />
    </div>
    }

    {formReady &&
    <HubspotFormWrapper
      cssClass={blockID}
      whiteButton={whiteButton}
      blackButton={blackButton}
      height={formHeight}
    />
    }

  </>

})

export default HubspotForms
